import React, { Ref } from 'react';
import Link from 'next/link';

import {
  ResultContainer,
  ResultSectionsContainer,
  ResultTitle,
} from './styled';
import ResultSection from './ResultSection';

import { ResultData } from 'types/search';

interface ComponentProps {
  resultData: ResultData;
  withXPadding?: boolean;
  noLinks?: boolean;
  scrollRef?: Ref<Element>;
  inPage?: boolean;
  searchEvent?: () => void;
}

const Result = ({
  resultData,
  withXPadding = false,
  noLinks = false,
  inPage = false,
  scrollRef,
  searchEvent,
}: ComponentProps): JSX.Element => (
  <ResultContainer
    inPage={inPage}
    // @ts-expect-error Card component does not suppor the ref prop
    ref={scrollRef}
    withXPadding={withXPadding}
  >
    {noLinks ? (
      <ResultTitle noLinks={noLinks} size="one">
        {resultData.title}
      </ResultTitle>
    ) : (
      <Link passHref href={`/articles/${resultData.id}`}>
        <ResultTitle
          onClick={() => searchEvent && searchEvent()}
          noLinks={noLinks}
          size="one"
        >
          {resultData.title}
        </ResultTitle>
      </Link>
    )}

    <ResultSectionsContainer>
      {resultData.sections &&
        resultData.sections.map((sectionData) => (
          <ResultSection
            key={sectionData.section_id}
            noLinks={noLinks}
            sectionData={sectionData}
          />
        ))}
    </ResultSectionsContainer>
  </ResultContainer>
);

export default Result;
