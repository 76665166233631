import React, { useCallback, MouseEventHandler, useContext } from 'react';
import { Badge, Body, spacing } from '@sumup/circuit-ui';
import { Search } from '@sumup/icons';
import { OptimizelyContext } from '@optimizely/react-sdk';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Image from 'next/image';

import {
  LinkToSearch,
  AiEntryPointText,
  AiEntryPointContainer,
  AiEntryPointDescription,
  SuggestionsContainer,
  SuggestionWrapper,
  IntroAnimationContainer,
} from './components/styled';

import * as Analytics from 'services/analytics';
import AiIllustrationEntryPoint from 'src/assets/icons/AiIllustrationEntryPoint';

const AiSearchEntryPoint = ({ searchTerm }: { searchTerm: string }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { optimizely } = useContext(OptimizelyContext);
  const enforceSelfClick: MouseEventHandler<HTMLParagraphElement> &
    MouseEventHandler<HTMLAnchorElement> &
    MouseEventHandler<HTMLButtonElement> = ({ target }) =>
    (target as HTMLAnchorElement).click();

  const sendOptimizelyEvent = useCallback(() => {
    optimizely?.track('search_initiated', optimizely?.user?.id, {});
  }, [optimizely]);

  const sendSmartSearchEvent = useCallback(() => {
    Analytics.AnalyticsEvents.sendEvent({
      'event': 'interaction',
      'action': 'open_ai_search_clicked',
      'target': 'customer_support_centre',
      'target-properties': '',
      'app_screen_name': '',
      'widget_name': '',
      'locale': router.locale,
      'business_feature': 'search',
      'internal_origin': 'search-bar',
    });
    Analytics.AnalyticsEvents.sendButtonClick({
      buttonDescription: 'open_ai_search_opening',
      internalOrigin: 'search',
      locale: router.locale,
      modalName: 'null',
      searchQuery: searchTerm,
    });
  }, [router.locale, searchTerm]);

  const sendClassicSearchEvent = useCallback(() => {
    Analytics.AnalyticsEvents.sendEvent({
      'event': 'interaction',
      'action': 'intended_search_submitted',
      'target': 'customer_support_centre',
      'target-properties': searchTerm,
      'app_screen_name': '',
      'widget_name': '',
      'locale': router.locale,
      'business_feature': 'search',
      'report_type': 'classic',
    });
    Analytics.AnalyticsEvents.sendButtonClick({
      buttonDescription: 'intended_search_submission',
      searchQuery: searchTerm,
      option: 'classic',
      locale: router.locale,
    });
  }, [router, searchTerm]);

  return (
    <SuggestionsContainer>
      <SuggestionWrapper
        onClick={() => {
          sendSmartSearchEvent();
          sendOptimizelyEvent();
        }}
      >
        <Link passHref href={'/ai-search'}>
          <LinkToSearch
            onMouseDown={enforceSelfClick}
            variant="highlight"
            size="one"
          >
            <AiEntryPointContainer>
              <AiEntryPointText>
                <IntroAnimationContainer>
                  <AiIllustrationEntryPoint />
                  <Image
                    src={'/smartsearchworking.gif'}
                    width="20"
                    height="20"
                    layout="fixed"
                    alt="AI Animation"
                  />
                </IntroAnimationContainer>
                <Body variant="highlight">{t('ai_search.entry_point')}</Body>
                <Badge variant="promo" css={spacing({ left: 'kilo' })}>
                  {t('ai_search.beta')}
                </Badge>
              </AiEntryPointText>
              <AiEntryPointDescription>
                {t('ai_search.experimental')}
              </AiEntryPointDescription>
            </AiEntryPointContainer>
          </LinkToSearch>
        </Link>
      </SuggestionWrapper>
      {searchTerm ? (
        <SuggestionWrapper
          onClick={() => {
            sendClassicSearchEvent();
            sendOptimizelyEvent();
          }}
        >
          <Link passHref href={`/search/${searchTerm}`}>
            <LinkToSearch
              onMouseDown={enforceSelfClick}
              variant="highlight"
              size="one"
            >
              <Search size="24" />
              {t('ai_search.classic_search')}
            </LinkToSearch>
          </Link>
        </SuggestionWrapper>
      ) : null}
    </SuggestionsContainer>
  );
};

export default AiSearchEntryPoint;
