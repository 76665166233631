import React, { FC } from 'react';
import { Entry } from 'contentful';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ContentfulCountry } from 'types/common';
import { CustomLiveAgentConfig } from 'src/types/context';
import { useLiveAgent } from 'src/providers/LiveAgent';

const Container = styled('div')(
  ({ shouldOpen }: { shouldOpen: boolean }) => css`
    bottom: 0px;
    right: 0px;
    position: fixed;
    width: ${shouldOpen ? '300px' : '0px'};
    height: ${shouldOpen ? '500px' : '0px'};
    z-index: 1;
  `,
);

const LiveAgent: FC<{
  countryData: Entry<ContentfulCountry>;
  customConfig?: CustomLiveAgentConfig;
}> = () => {
  const liveAgentContext = useLiveAgent();

  return (
    <Container id="liveAgentContainer" shouldOpen={liveAgentContext.shouldOpen}>
      <iframe
        style={{ width: '100%', height: '100%' }}
        id="liveAgent"
        title="Live Agent"
        src="/liveAgent.html"
      />
    </Container>
  );
};

export default LiveAgent;
