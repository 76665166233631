import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { get } from 'lodash/fp';
import { Entry } from 'contentful';

import { useUser } from '../User';
import { useCustomLiveAgent } from '../CustomLiveAgent';

import getEnvFromUrl from 'services/get-env-from-url';
import { LiveAgentContextProps } from 'types/context';
import {
  BUTTON_IDS,
  CHAT_LOAD_STORAGE_KEY,
  CHAT_CONFIG_STORAGE_KEY,
} from 'constants/salesforce';
import { getJsonFromSession, setJsonToSession } from 'services/storage';
import { Children, ContentfulCountry } from 'types/common';
import LiveAgent from 'src/components/LiveAgent/LiveAgent';

const LiveAgentContext = createContext<LiveAgentContextProps>(null);

const LiveAgentProvider: FC<{
  locale: string;
  shouldLoad: boolean;
  children: Children;
  countryData: Entry<ContentfulCountry>;
  articleId?: string;
}> = ({ children, locale, shouldLoad, countryData, articleId }) => {
  const [didInject, setDidInject] = useState<boolean>(false);
  const { customLiveAgentConfig } = useCustomLiveAgent();
  const [shouldOpen, setShouldOpen] = useState<boolean>(false);
  const [loadChat, setLoadChat] = useState(false);

  const env = useMemo(
    () => typeof window !== 'undefined' && getEnvFromUrl(window.location.href),
    [],
  );

  const userContext = useUser();

  useEffect(() => {
    const sessionLoadChat = getJsonFromSession(CHAT_LOAD_STORAGE_KEY);
    if (sessionLoadChat) {
      setLoadChat(true);
    }
  }, []);

  useEffect(() => {
    if (
      window &&
      ((shouldLoad &&
        !didInject &&
        BUTTON_IDS[locale] &&
        get('merchant_profile.merchant_code', userContext.user)) ||
        customLiveAgentConfig.openChat)
    ) {
      setJsonToSession(CHAT_CONFIG_STORAGE_KEY, {
        locale,
        env,
        countryData,
        articleId,
      });
      setLoadChat(true);
      setJsonToSession(CHAT_LOAD_STORAGE_KEY, true);
    }
  }, [
    locale,
    didInject,
    shouldLoad,
    setLoadChat,
    userContext.user,
    customLiveAgentConfig.openChat,
    env,
    countryData,
    articleId,
  ]);

  const providerValue = useMemo(
    () => ({
      didInject,
      setDidInject,
      shouldOpen,
      setShouldOpen,
    }),
    [didInject, setDidInject, shouldOpen, setShouldOpen],
  );

  return (
    <LiveAgentContext.Provider value={providerValue}>
      {loadChat && <LiveAgent countryData={countryData} />}
      {children}
    </LiveAgentContext.Provider>
  );
};

const useLiveAgent = (): LiveAgentContextProps => useContext(LiveAgentContext);

export { LiveAgentProvider, useLiveAgent };
