import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getJsonFromLocal, setJsonToLocal } from 'services/storage';
import { CUSTOM_LIVE_AGENT } from 'src/constants/salesforce';
import { Children } from 'types/common';
import { CustomLiveAgentConfig } from 'types/context';

const CustomLiveAgentContext =
  createContext<{
    customLiveAgentConfig: CustomLiveAgentConfig;
    setCustomLiveAgentConfig: Dispatch<CustomLiveAgentConfig>;
  }>(null);

const CustomLiveAgentProvider = ({
  children,
}: {
  children: Children;
}): JSX.Element => {
  const [customLiveAgentConfig, setCustomLiveAgentConfig] =
    useState<CustomLiveAgentConfig>(
      {
        ...(getJsonFromLocal(
          'CUSTOM_LIVE_AGENT',
        ) as unknown as CustomLiveAgentConfig),
        openChat: false,
      } || {
        environment: '',
        country: '',
        customEmail: '',
        customMC: '',
        openChat: false,
      },
    );

  useEffect(() => {
    setJsonToLocal(CUSTOM_LIVE_AGENT, customLiveAgentConfig);
  }, [customLiveAgentConfig]);

  return (
    <CustomLiveAgentContext.Provider
      value={{ customLiveAgentConfig, setCustomLiveAgentConfig }}
    >
      {children}
    </CustomLiveAgentContext.Provider>
  );
};

const useCustomLiveAgent = (): {
  customLiveAgentConfig: CustomLiveAgentConfig;
  setCustomLiveAgentConfig: Dispatch<CustomLiveAgentConfig>;
} =>
  useContext<{
    customLiveAgentConfig: CustomLiveAgentConfig;
    setCustomLiveAgentConfig: Dispatch<CustomLiveAgentConfig>;
  }>(CustomLiveAgentContext);

export { CustomLiveAgentProvider, useCustomLiveAgent };
