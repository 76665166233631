export const DASHBOARD_URL = 'https://me.sumup.com/support';

export const SUBPATH_TYPES = {
  'articles': 'articles',
  'sections': 'sections',
  '404': '404',
};

export const SUBPATH_PARAMS = {
  articles: 'articleSlug',
  sections: 'sectionSlug',
};

export const SUBPATH_NAMES = {
  'articles': '/articles/[articleSlug]',
  'sections': '/sections/[sectionSlug]',
  '404': '/404',
};

export const APP_DOMAINS: {
  default: string;
  zendesk: string;
  no_proto: string;
} = {
  default: 'https://help.sumup.com',
  zendesk: 'https://support.sumup.com',
  no_proto: 'help.sumup.com',
};

export const WHATSAPP_ENABLED_COUNTRIES = ['BR'];

export const WHATSAPP_URLS: {
  [key: string]: string;
} = {
  BR: 'https://api.whatsapp.com/send?l=pt_br&phone=551130807797&text=Oi,%20SumUp',
};

export const ESCALATION_TYPES = {
  CHAT: 'chat',
  WHATSAPP: 'whatsapp',
  EMAIL: 'email',
};

export const COOKIES: {
  APP_LOCALE: string;
} = {
  APP_LOCALE: 'APP_LOCALE',
};

export const BANNER_LOCALE = [
  'en-GB',
  'fr-FR',
  'it-IT',
  'es-ES',
  'en-IE',
  'nl-NL',
  'de-AT',
  'pt-PT',
  'fi-FI',
  'en-MT',
];
export const BANNER_STORAGE_KEY = 'bannerEnabled';

export const APP_ENVS: {
  LIVE: 'production';
  STAGE: 'staging';
  THETA: 'development';
} = {
  LIVE: 'production',
  STAGE: 'staging',
  THETA: 'development',
};

export const EU_DTS_ENABLED_COUNTRIES = [
  'en-IE',
  'de-DE',
  'fr-FR',
  'nl-NL',
  'it-IT',
  'es-ES',
];

export const BR_DTS_ENABLED_ARTICLES = [
  '70auBV1suiIVTTysHkVlCi',
  'wFOR7xKT1eZRkYfsR6iEI',
  '7ovdM9A05RmHjIuDDvzN5H',
];

export const DTS_ENABLED_ARTICLES = ['1fH1DQy2SCfsXvrdb76MS0'];

export const SMART_SEARCH_EXPERIMENT_KEY =
  '_support-centre_smart_search_experiment';

export const SMART_SEARCH_IT_EXPERIMENT_KEY =
  '_support-centre_smart_search_experiment_it';
