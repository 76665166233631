import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Row as UntypedRow, Col as UntypedCol } from '@sumup/circuit-ui';

import { Children } from 'types/common';

export const Row = styled(UntypedRow)(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_props: { children?: Children }) => css``,
);

export const Col = styled(UntypedCol)(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_props: { children?: Children }) => css``,
);
