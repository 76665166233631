import {
  FlagAt,
  FlagBe,
  FlagBg,
  FlagBr,
  FlagCh,
  FlagCl,
  FlagCo,
  FlagCy,
  FlagCz,
  FlagDe,
  FlagDk,
  FlagEe,
  FlagEs,
  FlagFi,
  FlagFr,
  FlagGb,
  FlagGr,
  FlagHu,
  FlagIe,
  FlagIt,
  FlagLt,
  FlagLu,
  FlagLv,
  FlagMt,
  FlagNl,
  FlagNo,
  FlagPl,
  FlagPt,
  FlagRo,
  FlagSe,
  FlagSk,
  FlagSl,
  FlagUs,
  FlagPe,
} from '@sumup/icons';

// For the MVP, UK will be the default country
export const DEFAULT_COUNTRY = 'GB';
export const SC_URL = 'https://support.sumup.com/hc/';
export const MENU_HEIGHT = 270;
export const MENU_WIDTH = 265;
export const ENTER_KEY = 13;
export const CURRENTLY_FOCUSED_COUNTRY = '.react-select__option--is-focused';
export const OPEN_MENU_CLASS = 'openMenu';
export const CLOSE_MENU_CLASS = 'closeMenu';
export const CUSTOM_SELECTOR_PROPS: {
  className: string;
  classNamePrefix: string;
  maxMenuHeight: number;
  menuPlacement: 'top' | 'bottom' | 'auto';
  isSearchable: false;
} = {
  className: 'react-select-container',
  classNamePrefix: 'react-select',
  isSearchable: false,
  maxMenuHeight: MENU_HEIGHT,
  menuPlacement: 'top',
};
export const SELECTED_COUNTRY = 'selected';

export const COUNTRIES = {
  AT: {
    flag: FlagAt,
    name: 'Austria',
    zendeskLocale: 'de-at',
  },
  BE: {
    flag: FlagBe,
    name: 'Belgium',
    zendeskLocale: 'nl-be',
  },
  BR: {
    flag: FlagBr,
    name: 'Brazil',
    zendeskLocale: 'pt-br',
  },
  BG: {
    flag: FlagBg,
    name: 'Bulgaria',
    zendeskLocale: 'bg',
  },
  CL: {
    flag: FlagCl,
    name: 'Chile',
    zendeskLocale: 'es-cl',
  },
  CO: {
    flag: FlagCo,
    name: 'Colombia',
    zendeskLocale: 'es-co',
  },
  CY: {
    flag: FlagCy,
    name: 'Cyprus',
    zendeskLocale: 'el',
  },
  CS: {
    flag: FlagCz,
    name: 'Czech Republic',
    zendeskLocale: 'cs',
  },
  DA: {
    flag: FlagDk,
    name: 'Denmark',
    zendeskLocale: 'da',
  },
  ET: {
    flag: FlagEe,
    name: 'Estonia',
    zendeskLocale: 'et',
  },
  FI: {
    flag: FlagFi,
    name: 'Finland',
    zendeskLocale: 'fi',
  },
  FR: {
    flag: FlagFr,
    name: 'France',
    zendeskLocale: 'fr',
  },
  DE: {
    flag: FlagDe,
    name: 'Germany',
    zendeskLocale: 'de',
  },
  EL: {
    flag: FlagGr,
    name: 'Greece',
    zendeskLocale: 'el',
  },
  HU: {
    flag: FlagHu,
    name: 'Hungary',
    zendeskLocale: 'hu',
  },
  IE: {
    flag: FlagIe,
    name: 'Ireland',
    zendeskLocale: 'en-ie',
  },
  IT: {
    flag: FlagIt,
    name: 'Italy',
    zendeskLocale: 'it',
  },
  LV: {
    flag: FlagLv,
    name: 'Latvia',
    zendeskLocale: 'lv',
  },
  LT: {
    flag: FlagLt,
    name: 'Lithuania',
    zendeskLocale: 'lt',
  },
  LU: {
    flag: FlagLu,
    name: 'Luxembourg',
    zendeskLocale: 'fr-lu',
  },
  MT: {
    flag: FlagMt,
    name: 'Malta',
    zendeskLocale: 'en-gb',
  },
  NL: {
    flag: FlagNl,
    name: 'Netherlands',
    zendeskLocale: 'nl',
  },
  NO: {
    flag: FlagNo,
    name: 'Norway',
    zendeskLocale: 'no',
  },
  PL: {
    flag: FlagPl,
    name: 'Poland',
    zendeskLocale: 'pl',
  },
  PT: {
    flag: FlagPt,
    name: 'Portugal',
    zendeskLocale: 'pt',
  },
  RO: {
    flag: FlagRo,
    name: 'Romania',
    zendeskLocale: 'ro',
  },
  SK: {
    flag: FlagSk,
    name: 'Slovakia',
    zendeskLocale: 'sk',
  },
  SL: {
    flag: FlagSl,
    name: 'Slovenia',
    zendeskLocale: 'sl',
  },
  ES: {
    flag: FlagEs,
    name: 'Spain',
    zendeskLocale: 'es',
  },
  SE: {
    flag: FlagSe,
    name: 'Sweden',
    zendeskLocale: 'sv',
  },
  SV: {
    flag: FlagSe,
    name: 'Sweden',
    zendeskLocale: 'sv',
  },
  CH: {
    flag: FlagCh,
    name: 'Switzerland',
    zendeskLocale: 'fr-ch',
  },
  GB: {
    flag: FlagGb,
    name: 'United Kingdom',
    zendeskLocale: 'en-gb',
  },
  US: {
    flag: FlagUs,
    name: 'United States',
    zendeskLocale: 'en-us',
  },
  PE: {
    flag: FlagPe,
    name: 'Peru',
    zendeskLocale: '',
  },
};

export const MARKETING_WEBSITE_DOMAINS = {
  'en-GB': 'https://sumup.co.uk',
  'en-IE': 'https://sumup.ie',
  'en-MT': 'https://sumup.com.mt',
  'en-US': 'https://sumup.com',
  'bg-BG': 'https://sumup.bg',
  'cs-CZ': 'https://sumup.cz',
  'da-DK': 'https://sumup.dk',
  'de-AT': 'https://sumup.at',
  'de-CH': 'https://ch-de.sumup.com',
  'de-DE': 'https://sumup.de',
  'de-LU': 'https://de.sumup.lu/',
  'el-CY': 'https://sumup.com.cy',
  'el-GR': 'https://sumup.gr',
  'es-CL': 'https://sumup.cl',
  'es-CO': 'https://sumup.co',
  'es-ES': 'https://sumup.es',
  'et-EE': 'https://sumup.ee',
  'fi-FI': 'https://sumup.fi',
  'fr-BE': 'https://fr.sumup.be/',
  'fr-CH': 'https://ch-fr.sumup.com',
  'fr-FR': 'https://sumup.fr',
  'fr-LU': 'https://fr.sumup.lu',
  'hr-HR': 'https://sumup.hr',
  'hu-HU': 'https://sumup.hu',
  'it-CH': 'https://ch-it.sumup.com',
  'it-IT': 'https://sumup.it',
  'lt-LT': 'https://sumup.lt',
  'lv-LV': 'https://sumup.lv',
  'nb-NO': 'https://sumup.no',
  'nl-BE': 'https://nl.sumup.be',
  'nl-NL': 'https://sumup.nl',
  'pl-PL': 'https://sumup.pl',
  'pt-BR': 'https://sumup.com.br',
  'pt-PT': 'https://sumup.pt',
  'ro-RO': 'https://sumup.ro',
  'sk-SK': 'https://sumup.sk',
  'sl-SI': 'https://sumup.si',
  'sv-SE': 'https://sumup.se',
  'es-PE': 'https://sumup.pe',
};
