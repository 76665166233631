import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { FC } from 'react';

const SVG = styled('svg')(
  () => css`
    position: absolute;
    z-index: 1;
    transition: opacity 0.2s ease;
  `,
);

const AiIllustration: FC = () => (
  <SVG
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" fill="black" />
    <path
      d="M8.33398 12.332C14.334 12.332 15.334 8.33199 15.334 2.33203C15.334 8.33179 16.334 12.332 22.334 12.332C16.334 12.332 15.3341 16.3318 15.334 22.332C15.334 16.3318 14.334 12.332 8.33398 12.332Z"
      fill="white"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M5.33398 19.332C7.47684 19.332 7.83398 18.132 7.83398 16.332C7.83398 18.132 8.19113 19.332 10.334 19.332C8.19113 19.332 7.83404 20.532 7.83398 22.332C7.83398 20.532 7.47684 19.332 5.33398 19.332Z"
      fill="white"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M2.33398 6.33203C4.90541 6.33203 5.33398 4.73201 5.33398 2.33203C5.33398 4.73193 5.76256 6.33203 8.33398 6.33203C5.76256 6.33203 5.33405 7.93193 5.33398 10.332C5.33398 7.93193 4.90541 6.33203 2.33398 6.33203Z"
      fill="white"
      stroke="white"
      strokeWidth="2"
      strokeLinejoin="round"
    />
  </SVG>
);

export default AiIllustration;
