import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  Dispatch,
  FC,
} from 'react';
import { useTranslation } from 'next-i18next';
import axios from 'axios';
import { useRouter } from 'next/router';
import { Entry } from 'contentful';

import CountryOptions from '../CountryOptions';
import LanguageOptions from '../LanguageOptions';

import { localeInfo } from 'services/get-locale-info';
import {
  HeaderCountryPickerContainer,
  CountryPickerTabs,
  TabButton as StyledTabButton,
  TabsContainer,
  TabListBorder,
} from 'components/Header/components/styled';
import { DesktopComponent } from 'components/styledComponents';
import { ContentfulCountry } from 'types/common';

const TabButton = StyledTabButton as unknown as FC<{
  children: string;
  selected: boolean;
  onClick: () => void;
}>;

type CountryPickerProps = {
  isCountryPickerOpened: boolean;
  setIsCountryPickerOpened: Dispatch<boolean>;
  countriesData: Entry<ContentfulCountry>[];
};

const DesktopCountryPicker: FC<CountryPickerProps> = ({
  isCountryPickerOpened,
  setIsCountryPickerOpened,
  countriesData = [],
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const navigationRef = useRef<HTMLDivElement>();
  const currentCountry = localeInfo(router.locale).country;
  const [selected, setSelected] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [enabledLocales, setEnabledLocales] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    const fetchLocales = async () => {
      if (!enabledLocales.length) {
        const response: { data: { enabledLocales: string } } = await axios.get(
          '/api/localization/enabled-locales',
        );

        if (!isCancelled) {
          setEnabledLocales(response.data.enabledLocales.split(','));
        }
      }
    };
    fetchLocales().catch(console.log);
    return () => {
      isCancelled = true;
    };
  }, [enabledLocales.length]);

  const selectedCountryData = useMemo(
    () =>
      countriesData.find(
        (country) => country.fields.twoLetterIsoCode === selectedCountry,
      ),
    [countriesData, selectedCountry],
  );

  const countriesList = useMemo(
    () =>
      countriesData
        ? countriesData
            .map((el) => el.fields)
            .sort((a, b) => (a.name > b.name ? 1 : -1))
        : [],
    [countriesData],
  );

  const handleCountryClick = (el: ContentfulCountry): void => {
    const availableLanguages = el.supportedLanguages.map((item) => item.fields);
    setSelected(true);
    setSelectedCountry(el.twoLetterIsoCode);
    setLanguages(availableLanguages.map((language) => language));
  };

  useEffect(() => {
    const handleOutsideClick: EventListener = (e) => {
      if (
        navigationRef.current &&
        typeof navigationRef.current === 'object' &&
        !navigationRef.current.contains(e.target as HTMLElement)
      ) {
        setIsCountryPickerOpened(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  });

  return (
    <>
      <DesktopComponent>
        {isCountryPickerOpened && (
          <HeaderCountryPickerContainer ref={navigationRef}>
            <TabsContainer>
              <CountryPickerTabs>
                <TabButton
                  key={'Country'}
                  selected={!selected}
                  onClick={() => {
                    setSelected(false);
                    setLanguages([]);
                  }}
                >
                  {t('header.select_country')}
                </TabButton>
                {languages.length > 0 && (
                  <TabButton
                    key={'Language'}
                    selected={selected}
                    onClick={() => setSelected(true)}
                  >
                    {t('header.select_language')}
                  </TabButton>
                )}
                <TabListBorder />
              </CountryPickerTabs>
              {!selected ? (
                <CountryOptions
                  countriesList={countriesList}
                  currentCountry={currentCountry}
                  enabledLocales={enabledLocales}
                  handleCountryClick={handleCountryClick}
                />
              ) : (
                <LanguageOptions
                  enabledLocales={enabledLocales}
                  selectedCountryData={selectedCountryData}
                  router={router}
                />
              )}
            </TabsContainer>
          </HeaderCountryPickerContainer>
        )}
      </DesktopComponent>
    </>
  );
};
export default DesktopCountryPicker;
