import { NotificationListener } from '@optimizely/optimizely-sdk';
import {
  ActivateListenerPayload,
  createInstance,
  enums,
  ReactSDKClient,
  setLogLevel,
} from '@optimizely/react-sdk';
import { v4 as uuid } from 'uuid';
import Cookies from 'js-cookie';
import bowser from 'bowser';

import { getActiveConsentCategories } from 'components/CookieConsent/public';
import {
  DOMAIN_INCLUDING_SUBDOMAINS,
  OPTIMIZELY_USER_COOKIE,
} from 'src/constants/cookies';
import { User } from 'src/types/context';

export type OptimizelyUserAttributes = {
  'browser-language'?: string;
  'browser-name'?: string;
  'browser-version'?: string;
  'device-type'?: string;
  'is-returning-session'?: 'yes' | 'no';
  'os-name'?: string;
  'os-version'?: string;
  'referrer-url'?: string;
  'merchant-has-lending-offer'?: boolean;
  'querystring'?: string;
  'cookiestring'?: string;
  'locale'?: string;
  'MerchantCode'?: string;
  /**
   * @deprecated Use the `MerchantCode` attribute instead.
   */
  'mid'?: string;
  'MerchantCountry'?: string;
};

/**
 * Concatenate the values of all strings in same format as GET parameters
 * @originalString {string} cookie string "aa=bb; cc=dd"
 * @returns {string} "aa=bb&cc=dd"
 */
export function normalizeCookieString(originalString = ''): string {
  return String(originalString)
    .split(';')
    .map((val) => val.trim())
    .filter(Boolean)
    .join('&')
    .replace(/&$/, '');
}

export function createClient() {
  const client = createInstance({
    sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
  });

  const originalTrack = client.track.bind(client) as ReactSDKClient['track'];

  // We're only allowed to track users that have given their consent.
  client.track = (...args) => {
    const categories = getActiveConsentCategories();

    if (categories.includes('FUNCTIONAL')) {
      originalTrack(...args);
    }
  };

  return client;
}

setLogLevel(
  process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_LOG_LEVEL || enums.LOG_LEVEL.ERROR,
);

export const optimizelyClient = createInstance({
  sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
});

export const onActivate: NotificationListener<ActivateListenerPayload> = ({
  experiment,
  variation,
}) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push({
    event: 'optimizely',
    customParameters: {
      optimizelyExperimentKey: experiment.key,
      optimizelyVariationKey: variation.key,
    },
  });
};

optimizelyClient.notificationCenter.addNotificationListener(
  enums.NOTIFICATION_TYPES.ACTIVATE,
  onActivate,
);

/**
 * @isReturningSession {boolean} if the current session has an Optimizely user ID
 */
export function getOptimizelyUserAttributes({
  user,
  locale,
  isReturningSession = false,
}: {
  user: User;
  locale: string;
  isReturningSession: boolean;
}): OptimizelyUserAttributes {
  const browserInfo =
    global?.navigator?.userAgent && bowser.parse(global?.navigator?.userAgent);
  const browserLanguage =
    global?.navigator?.languages &&
    ((global?.navigator?.languages && global?.navigator?.languages[0]) || // chrome / firefox
      global?.navigator?.language); // other browsers
  const cookiestring =
    typeof document !== 'undefined' && normalizeCookieString(document?.cookie);
  const merchantCode = user?.merchant_profile?.merchant_code;
  const merchantCountry = user?.merchant_profile?.country;
  const gaId =
    (global?.window?.gaGlobal?.vid && global?.window?.gaGlobal?.vid) || null;

  const optimizelyAttributes = {
    'browser-language': browserLanguage,
    'browser-name': browserInfo?.browser?.name,
    'browser-version': browserInfo?.browser?.version,
    'device-type': browserInfo?.platform?.type,
    'os-name': browserInfo?.os?.name,
    'os-version': browserInfo?.os?.version,
    'referrer-url': typeof document !== 'undefined' && document?.referrer,
    'is-returning-session': isReturningSession ? 'yes' : 'no',
    'merchant-has-lending-offer': false,
    'querystring':
      typeof window !== 'undefined' && window?.location?.href.split('?')[1], // .href instead of .search for old browsers
    cookiestring,
    locale,
    'MerchantCode': merchantCode,
    /**
     * @deprecated Use the `MerchantCode` attribute instead.
     */
    'mid': merchantCode,
    'MerchantCountry': merchantCountry,
    'google-analytics-user-id': gaId,
  };

  const emptyValues = ['', null, undefined] as const;
  const nonEmptyOptimizelyAttributes = Object.fromEntries(
    Object.entries(optimizelyAttributes).filter(
      ([, value]) => !emptyValues.includes(value),
    ),
  );

  return nonEmptyOptimizelyAttributes;
}

export const ONE_YEAR = 365;

export const getOptimizelyUser = ({
  user,
  locale,
}: {
  user?: User;
  locale: string;
}) => {
  const cookie = Cookies.get(OPTIMIZELY_USER_COOKIE);

  const userId = cookie || uuid();
  const isReturningSession = Boolean(cookie);

  if (!cookie) {
    Cookies.set(OPTIMIZELY_USER_COOKIE, userId, {
      expires: ONE_YEAR,
      domain: DOMAIN_INCLUDING_SUBDOMAINS,
    });
  }

  return {
    id: userId,
    attributes: getOptimizelyUserAttributes({
      user,
      isReturningSession,
      locale,
    }),
  };
};
