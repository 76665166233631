import React, {
  FC,
  useState,
  useEffect,
  useRef,
  ChangeEvent,
  useCallback,
  Dispatch,
  SetStateAction,
  KeyboardEventHandler,
} from 'react';
import noScroll from 'no-scroll';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Entry } from 'contentful';
import { useExperiment } from '@optimizely/react-sdk';

import HeaderBranding from '../Header/components/HeaderBranding';
import HeaderNavigation from '../Header/components/HeaderNavigation';
import {
  Navigation,
  NavHeader,
  NavSearchBar,
  NavSearchContainer,
  NavigationWrapper,
  NavSearchOverlay,
  NavBarClear,
  SearchSuggestionsContainer,
  TopNavSearchContainer,
} from '../Header/components/styled';

import { SmallGrid } from 'components/styledComponents';
import { useScroll } from 'providers/SearchScroll';
import AiSearchEntrypoint from 'components/AiSearchEntryPoint';
import SearchSuggestions from 'components/SearchSuggestions';
import { SearchResults } from 'types/search';
import { showSmartSearch } from 'services/common';
import { getSearchResults } from 'services/api/search';
import * as Analytics from 'services/analytics';
import { getCurrentPageName } from 'services/pages/common';
import { isPosPage } from 'services/pos';
import { ContentfulCountry } from 'types/common';
import * as ANALYTICS_CONSTANTS from 'constants/analytics';
import {
  INITIAL_PAGE,
  EMPTY_SEARCH_RESULTS,
  RESULTS_PER_PAGE_SEARCH_BAR,
  MIN_LENGTH_OF_SEARCH_TERM,
} from 'constants/search';
import { KEY_CODES } from 'constants/events';
import {
  SMART_SEARCH_EXPERIMENT_KEY,
  SMART_SEARCH_IT_EXPERIMENT_KEY,
} from 'constants/common';

type ComponentProps = {
  overlayEnabled: boolean;
  setOverlayEnabled: Dispatch<SetStateAction<boolean>>;
  countriesData: Entry<ContentfulCountry>[];
};

const Component: FC<ComponentProps> = ({
  overlayEnabled,
  setOverlayEnabled,
  countriesData,
}) => {
  const [isNavSearchVisible, setIsNavSearchVisible] = useState(false);
  const [isNavSearchExpanded, setIsNavSearchExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] =
    useState<SearchResults>(EMPTY_SEARCH_RESULTS);
  const [trackingQuery, setTrackingQuery] = useState('');
  const [shouldDisplayAiEntryPoint, setShouldDisplayAiEntryPoint] =
    useState(false);
  const [shouldShowEntryPoint, setShouldShowEntryPoint] = useState(false);

  const router = useRouter();
  const { t } = useTranslation();
  const scrollContext = useScroll();
  const searchRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const navigationRef = useRef<HTMLDivElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);

  const [variation, clientReady] = useExperiment(
    router.locale === 'it-IT'
      ? SMART_SEARCH_IT_EXPERIMENT_KEY
      : SMART_SEARCH_EXPERIMENT_KEY,
    {
      autoUpdate: true,
    },
  );

  const isSearchResultsPage = () => router.pathname.includes('/search');

  const handleChange = ({
    target: { value: inputValue },
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(inputValue);
  };

  const enableOverlay = useCallback(() => {
    if (scrollContext.isScrolled) {
      setOverlayEnabled(true);
      setShouldDisplayAiEntryPoint(true);
    }
  }, [scrollContext.isScrolled, setOverlayEnabled]);

  const disableOverlay = useCallback(() => {
    if (scrollContext.isScrolled) {
      setOverlayEnabled(false);
      setShouldDisplayAiEntryPoint(false);
    }
  }, [scrollContext.isScrolled, setOverlayEnabled]);

  const handleNavSearchBlur = useCallback(() => {
    if (scrollContext.isScrolled) {
      searchRef?.current?.blur();
      setSearchValue('');
      setSearchResults(EMPTY_SEARCH_RESULTS);
      setIsNavSearchExpanded(false);
      disableOverlay();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      noScroll.off();
    }
  }, [disableOverlay, scrollContext.isScrolled]);

  const sendSearchEvent = useCallback(
    () =>
      Analytics.AnalyticsEvents.sendSearchEvent({
        query: searchValue,
        event: ANALYTICS_CONSTANTS.EVENTS.INTERACTION,
        action: ANALYTICS_CONSTANTS.ACTIONS.SEARCH,
        pageName: getCurrentPageName(router),
        locale: router.locale,
      }),
    [router, searchValue],
  );

  const handleSearchBlur = () =>
    setTimeout(
      () => {
        if (searchValue !== trackingQuery) {
          Analytics.AnalyticsEvents.sendNewSearchEvent({
            query: searchValue,
            locale: router.locale,
            value: 'classic',
          });
        }
        setTrackingQuery('');
        if (!searchContainerRef?.current?.contains(document?.activeElement)) {
          handleNavSearchBlur();
        }
      },

      10,
    );

  const handleOutsideClick: EventListener = useCallback(
    (e) => {
      if (
        !navigationRef?.current?.contains(e.target as HTMLElement) &&
        !searchContainerRef?.current?.contains(e.target as HTMLElement)
      ) {
        handleNavSearchBlur();
      }
    },
    [handleNavSearchBlur],
  );
  const handleContainerFocus = () => {
    setIsNavSearchExpanded(true);
    enableOverlay();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    noScroll.on();
  };
  const handleKeyDown: KeyboardEventHandler = (e) => {
    switch (e.which) {
      case KEY_CODES.ENTER:
        if (searchValue.length) {
          sendSearchEvent();
          router.push(`/search/${searchValue}`).catch(() => {});
          handleNavSearchBlur();
        }
        break;
      case KEY_CODES.ESCAPE:
        handleNavSearchBlur();
        break;
      default:
        break;
    }
  };

  useEffect(handleNavSearchBlur, [router.asPath, handleNavSearchBlur]);

  useEffect(
    () => !overlayEnabled && handleNavSearchBlur(),
    [overlayEnabled, handleNavSearchBlur],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length >= MIN_LENGTH_OF_SEARCH_TERM) {
        getSearchResults({
          page: INITIAL_PAGE,
          size: RESULTS_PER_PAGE_SEARCH_BAR,
          locale: router.locale,
          searchTerm: searchValue,
        })
          .then((searchResponse) => setSearchResults(searchResponse.data))
          .catch(() => {});
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, router.locale]);

  useEffect(() => {
    if (scrollContext.isScrolled) {
      setIsNavSearchVisible(true);
    } else {
      setIsNavSearchVisible(false);
      handleNavSearchBlur();
    }
  }, [scrollContext.isScrolled, handleNavSearchBlur]);

  useEffect(() => {
    if (scrollContext.isScrolled) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [handleNavSearchBlur, handleOutsideClick, scrollContext.isScrolled]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length >= MIN_LENGTH_OF_SEARCH_TERM) {
        sendSearchEvent();
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchValue, sendSearchEvent]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchValue.length >= MIN_LENGTH_OF_SEARCH_TERM) {
        if (searchValue !== trackingQuery) {
          Analytics.AnalyticsEvents.sendNewSearchEvent({
            query: searchValue,
            locale: router.locale,
            value: 'classic',
          });
        }
        setTrackingQuery(searchValue);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [router.locale, searchValue, sendSearchEvent, trackingQuery]);

  useEffect(() => {
    if (showSmartSearch({ locale: router.locale, variation, clientReady })) {
      setShouldShowEntryPoint(true);
    } else {
      setShouldShowEntryPoint(false);
    }
  }, [clientReady, router.locale, variation]);

  return (
    <NavHeader
      isNavSearchVisible={isNavSearchVisible}
      isSearch={isSearchResultsPage()}
      isPos={isPosPage(router)}
      key={router.locale}
    >
      <SmallGrid>
        <Navigation ref={navigationRef}>
          <HeaderBranding countriesData={countriesData} />
          {isPosPage(router) ? null : (
            <NavigationWrapper>
              <NavSearchContainer
                ref={searchContainerRef}
                onBlur={handleSearchBlur}
                onFocus={handleContainerFocus}
                isNavSearchVisible={isNavSearchVisible}
                isNavSearchExpanded={isNavSearchExpanded}
              >
                <TopNavSearchContainer>
                  <NavSearchBar
                    ref={searchRef}
                    placeholder={t('header.search_placeholder')}
                    label={t('header.search_placeholder')}
                    hideLabel={true}
                    isNavSearchExpanded={isNavSearchExpanded}
                    value={searchValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                  />
                  <NavBarClear
                    size="24"
                    onClick={handleNavSearchBlur}
                    onTouchStart={handleNavSearchBlur}
                    isNavSearchExpanded={isNavSearchExpanded}
                  />
                </TopNavSearchContainer>
                {shouldShowEntryPoint && shouldDisplayAiEntryPoint ? (
                  <SearchSuggestionsContainer
                    fullWidth
                    onClick={handleNavSearchBlur}
                  >
                    <AiSearchEntrypoint searchTerm={searchValue} />
                  </SearchSuggestionsContainer>
                ) : (
                  <>
                    {searchResults?.articles?.length ? (
                      <SearchSuggestionsContainer
                        topNav
                        fullWidth
                        onClick={handleNavSearchBlur}
                      >
                        <SearchSuggestions
                          searchTerm={searchValue}
                          results={searchResults}
                        />
                      </SearchSuggestionsContainer>
                    ) : null}
                  </>
                )}
              </NavSearchContainer>
              <HeaderNavigation />
            </NavigationWrapper>
          )}
          <NavSearchOverlay isNavSearchExpanded={isNavSearchExpanded} />
        </Navigation>
      </SmallGrid>
    </NavHeader>
  );
};

export default Component;
