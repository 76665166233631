import { NextRouter } from 'next/router';
import { get } from 'lodash/fp';

import { AnyType } from 'types/common';
import { SUBPATH_NAMES, SUBPATH_PARAMS } from 'constants/common';
import { POS_PATH } from 'constants/pos';

export const returnValueOrNull = (value: AnyType): AnyType => value || null;

const encodeQueryParam = ({
  contentId,
  contentSlug,
}: {
  contentId: string;
  contentSlug: string;
}): string =>
  encodeURIComponent(`${contentId}${contentSlug ? `-${contentSlug}` : ''}`);

export const addSlugToUrl = ({
  contentId,
  contentSlug,
  isPos = false,
  subPath,
}: {
  contentId: string;
  contentSlug: string;
  isPos?: boolean;
  subPath: string;
}): {
  pathname: string;
  query?: {
    articleSlug?: string;
    sectionSlug?: string;
  };
} => ({
  pathname: `${isPos ? POS_PATH : ''}${SUBPATH_NAMES[subPath] as string}`,
  ...(contentId
    ? {
        query: {
          [SUBPATH_PARAMS[subPath]]: encodeQueryParam({
            contentId,
            contentSlug,
          }),
        },
      }
    : {}),
});

export const determineCanonicalTag = ({
  locale,
  subPath,
  pageSlug,
  contentId,
  contentSlug,
}: {
  locale: string;
  subPath: string;
  pageSlug: string;
  contentId: string;
  contentSlug: string;
}): boolean | string =>
  pageSlug !== `${contentId}-${contentSlug}` &&
  `${process.env.SITE_BASEURL}/${locale}${
    contentSlug
      ? `/${subPath}/${contentId}-${contentSlug}`
      : `/${subPath}/${contentId}`
  }`;

export type DecodeBase64String<T> = (encodedString: string) => T;

export const decodeBase64String: DecodeBase64String<Record<string, unknown>> = (
  encodedString,
) =>
  encodedString &&
  (JSON.parse(Buffer.from(encodedString, 'base64').toString()) as {
    [key: string]: string;
  });

export const removeTrailingSlash = (url: string): string =>
  url.replace(/\/$/, '');

export const isSumupUrl = (url: string): boolean => {
  const parsedUrl = new URL(url);
  return (
    parsedUrl.hostname.includes('sumup.com') ||
    parsedUrl.hostname.includes('sam-app.ro') ||
    parsedUrl.hostname.includes('sumup-vercel.app')
  );
};

export const createPathFromArray = (parts: string[]): string =>
  parts.reduce((acc, cur) => `${acc}/${cur}`, '');

export const checkQueryInRouter = (
  router: NextRouter,
  query: string,
): string | boolean =>
  (get(`query.${query}`, router) as string) ||
  get('asPath', router).includes(query);

export const cleanUpQueryParam = (queryParam: string) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(queryParam);
  window?.history?.pushState({}, '', url);
};

export const isWebCrawler = () =>
  navigator &&
  /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

export const renderSmartSearchRoute = ({ locale }: { locale: string }) =>
  process.env.NEXT_PUBLIC_SMART_SEARCH_ENABLED_LOCALES.includes(locale);

// only used for the purpose of the Optimizely experiment
// remove and replace usage with renderSmartSearchRoute
// after experiment is done
export const showSmartSearch = ({
  locale,
  clientReady,
  variation,
}: {
  locale: string;
  clientReady: boolean;
  variation: string | null;
}) => {
  if (renderSmartSearchRoute({ locale }) && clientReady) {
    if (variation === null) {
      return true;
    }
    if (variation === 'show_smart_search') {
      return true;
    }
    if (variation === 'do_not_show_smart_search') {
      return false;
    }
  }
  return false;
};
