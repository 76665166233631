import React, { useState, FC, useMemo, forwardRef, useCallback } from 'react';
import Link from 'next/link';
import { Body, Headline, useModal } from '@sumup/circuit-ui';
import { light as theme } from '@sumup/design-tokens';
import { css } from '@emotion/react';
import { Entry } from 'contentful';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { get } from 'lodash/fp';

import {
  Description,
  HeaderLogo,
  CountryFlagContainer,
  CountryFlag,
  HeaderLink,
  POSHeaderLogo,
} from '../styled';
import DesktopCountryPicker from '../CountryPicker/components/DesktopCountryPicker';
import MobileCountryPickerModal from '../CountryPicker/components/MobileCountryPickerModal';

import {
  DesktopComponent,
  MobileCountryPicker,
} from 'components/styledComponents';
import { POS_PATH } from 'constants/pos';
import { getCountryFromLocale } from 'services/localization';
import { isPosPage } from 'services/pos';
import { ContentfulCountry } from 'types/common';

// eslint-disable-next-line prefer-arrow-callback
const ForwardedHeaderLink = forwardRef(function CustomHeaderLink(
  { href, isPos = false }: { href?: string; isPos?: boolean },
  /* eslint-disable @typescript-eslint/no-unused-vars */
  _ref,
) {
  return (
    <HeaderLink href={href} as="link" aria-label="SumUp Logo">
      {isPos ? (
        <>
          <POSHeaderLogo />
          <Headline
            as={'h3'}
            size="four"
            css={{
              marginLeft: theme.spacings.byte,
              marginRight: theme.spacings.kilo,
            }}
          >
            {'POS Pro'}
          </Headline>
        </>
      ) : (
        <HeaderLogo />
      )}
    </HeaderLink>
  );
});

const HeaderBranding: FC<{
  countriesData: Entry<ContentfulCountry>[];
}> = ({ countriesData }) => {
  const { t } = useTranslation();
  const { setModal, removeModal } = useModal();
  const [isCountryPickerOpened, setIsCountryPickerOpened] = useState(false);
  const router = useRouter();

  const currentCountryData = useMemo(
    () =>
      countriesData?.find(
        (item) =>
          item.fields.twoLetterIsoCode === getCountryFromLocale(router.locale),
      ),
    [countriesData, router.locale],
  );

  const MemoizedMobileModal = useMemo(
    () => (
      <MobileCountryPickerModal
        removeModal={removeModal}
        countriesData={countriesData}
      />
    ),
    [countriesData, removeModal],
  );

  const handleMobileFlagClick = useCallback(
    () =>
      setModal({
        css: css`
          padding: 0 !important;
        `,
        closeButtonLabel: 'Close',
        children: MemoizedMobileModal,
      }),
    [MemoizedMobileModal, setModal],
  );

  const handleDesktopFlagClick = () =>
    setIsCountryPickerOpened(!isCountryPickerOpened);

  return (
    <Description>
      <Link href={isPosPage(router) ? POS_PATH : '/'} passHref>
        <ForwardedHeaderLink isPos={isPosPage(router)} />
      </Link>
      <Body size="one">{t('header.logo_description')}</Body>
      <DesktopComponent>
        <CountryFlagContainer
          aria-label="Country Picker"
          onClick={handleDesktopFlagClick}
        >
          <CountryFlag
            flagName={get('fields.flagName', currentCountryData) as string}
          />
        </CountryFlagContainer>
      </DesktopComponent>
      <MobileCountryPicker>
        <CountryFlagContainer onClick={handleMobileFlagClick}>
          <CountryFlag
            flagName={get('fields.flagName', currentCountryData) as string}
          />
        </CountryFlagContainer>
      </MobileCountryPicker>
      <DesktopCountryPicker
        countriesData={countriesData}
        isCountryPickerOpened={isCountryPickerOpened}
        setIsCountryPickerOpened={setIsCountryPickerOpened}
      />
    </Description>
  );
};

export default HeaderBranding;
