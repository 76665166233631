/* eslint-disable */
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Anchor, Card, Headline } from '@sumup/circuit-ui';

import { StyledComponentsParams } from 'types/components';
import { MAX_SEARCH_WIDTH_PX } from 'constants/search';

export const LinkToSearch = styled(Anchor)(
  ({ theme }: StyledComponentsParams) => css`
    padding: ${theme.spacings.giga};
    display: flex;
    justify-content: left;
    align-items: center;
    color: var(--cui-bg-strong);
    text-decoration: none;
    transition: all 0.18s ease;

    &:hover,
    &:visited:hover {
      text-decoration: none;
      color: var(--cui-bg-strong);
      background-color: var(--cui-bg-subtle);

      svg {
        opacity: 0;
      }
    }

    &:visited,
    &:visited:active,
    &:active,
    &:focus {
      color: var(--cui-bg-strong);
    }
    &:focus {
      z-index: ${theme.zIndex.absolute};
    }
  `,
);

export const AiEntryPointContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;

    svg {
      width: 20px;
    }

    ${theme.mq.untilMega} {
      flex-direction: column;
      justify-content: left;
    }
    ${theme.mq.mega} {
      flex-direction: row;
      align-items: center;
    }
  `,
);

export const AiEntryPointText = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;

    strong {
      color: var(--cui-bg-strong);
    }
  `,
);

export const AiEntryPointDescription = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    background-color: var(--cui-bg-subtle);
    color: var(--cui-fg-subtle);
    padding: ${theme.spacings.byte};
    border-radius: ${theme.borderRadius.byte};
    font-weight: normal;
    width: fit-content;

    ${theme.mq.untilMega} {
      margin-top: ${theme.spacings.byte};
    }
    ${theme.mq.mega} {
      margin-top: 0;
    }
  `,
);

export const ResultLink = styled(Anchor)(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    text-decoration: none;
    position: relative;

    &:focus {
      z-index: ${theme.zIndex.absolute};
    }
  `,
);

export const SuggestionWrapper = styled('div')(
  ({
    theme,
    linkToResults,
  }: StyledComponentsParams & { linkToResults?: boolean }) => css`
    width: 100%;
    ${!linkToResults &&
    `border-bottom: ${theme.borderWidth.kilo} solid var(--cui-bg-normal-pressed);`}

    &:hover {
      background-color: var(--cui-bg-subtle);
      ${!linkToResults &&
      `border-bottom: calc(2 * ${theme.borderWidth.mega}) solid
        var(--cui-bg-accent-strong)`};
      transition: all 0.18s ease;
    }
  `,
);

export const SuggestionsContainer = styled(Card)(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    max-width: ${MAX_SEARCH_WIDTH_PX}px;
    margin: auto;
    padding: 0;
    border-radius: ${theme.borderRadius.mega};
    overflow: auto;
  `,
);

export const PromoModalContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 680px;
    margin-top: ${theme.spacings.zetta};

    ${theme.mq.untilMega} {
      width: 100%;
    }
  `,
);

export const PromoModalContent = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    margin: 0 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.mq.untilMega} {
      margin: 0 ${theme.spacings.mega};
    }
  `,
);

export const PromoModalHeadline = styled(Headline)(
  ({ theme }: StyledComponentsParams) => css`
    margin-top: ${theme.spacings.giga};
    margin-bottom: ${theme.spacings.byte};

    ${theme.mq.untilMega} {
      ${theme.typography.headline.three}
    }
  `,
);

export const PromoModalButtons = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    flex-direction: row-reverse;
    margin-top: ${theme.spacings.zetta};
    gap: ${theme.spacings.mega};

    ${theme.mq.untilMega} {
      margin-top: ${theme.spacings.mega};
      flex-direction: column;
    }
  `,
);

export const IntroAnimationContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    margin-right: ${theme.spacings.kilo};
    background-color: var(--cui-bg-strong);
    padding: 2px;
  `,
);
