import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Hamburger,
  Anchor,
  Body,
  SearchInput,
  Button,
  Card,
  Tab,
  TabList,
  TabPanel,
} from '@sumup/circuit-ui';
import { Close } from '@sumup/icons';
import * as icons from '@sumup/icons';
import { Theme } from '@sumup/design-tokens';

import { MAX_SEARCH_WIDTH_PX } from 'constants/search';
import { StyledComponentsParams } from 'types/components';
import { Children } from 'types/common';
import SumupLogo from 'src/assets/icons/SumupLogo';

const COUNTRY_PICKER_WIDTH = 426;
const COUNTRY_PICKER_HEIGHT = 308;
const COUNTRY_PICKER_BUTTON_WIDTH = 42;
const TAB_BUTTON_HIGHLIGHT_HEIGHT = 2;
const TAB_BUTTON_HIGHLIGHT_OVERLAP = -1;

export const CountryFlagContainer = styled(Button)(
  ({ theme }: StyledComponentsParams) => css`
    border: none;
    margin: 0;
    padding: 0;
    border-radius: ${theme.borderRadius.bit};
    margin-left: ${theme.spacings.byte};

    ${theme.mq.giga} {
      padding: ${theme.spacings.kilo} ${theme.spacings.byte};
    }
  `,
);

export const CountryFlag = ({ flagName = '' }: { flagName: string }) => {
  const StyledFlag =
    flagName &&
    styled(icons[flagName])(
      ({ theme }: StyledComponentsParams) => css`
        width: calc(${theme.spacings.mega} + ${theme.spacings.bit});
        height: calc(${theme.spacings.mega} + ${theme.spacings.bit});

        ${theme.mq.giga} {
          width: ${theme.spacings.tera};
          height: ${theme.spacings.giga};
        }
      `,
    );

  return flagName ? <StyledFlag /> : null;
};

export const Description = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;
    position: relative;
    z-index: ${theme.zIndex.input};
  `,
);

export const DesktopMenu = styled('div')(`
  display: flex;
  flex-direction: row;
  align-items: center;
`);

export const Greeting = styled(Body)(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.spacings.tera};
    text-align: center;
    position: relative;
    z-index: ${theme.zIndex.popover};

    ${theme.mq.untilMega} {
      font-size: ${theme.typography.headline.two.fontSize};
      line-height: ${theme.typography.headline.two.lineHeight};
    }
    ${theme.mq.mega} {
      font-size: ${theme.typography.headline.one.fontSize};
      line-height: ${theme.typography.headline.one.lineHeight};
    }
  `,
);

const headerMargin = ({
  theme,
  isSearch,
  searchResults,
}: {
  theme: Theme;
  isSearch: boolean;
  searchResults: boolean;
}) => {
  if (isSearch && !searchResults) {
    return theme.spacings.mega;
  }
  return theme.spacings.zetta;
};

export const Header = styled('header')(
  ({
    theme,
    isPos = false,
    isSearch,
    searchResults,
  }: StyledComponentsParams & {
    isPos?: boolean;
    isSearch: boolean;
    searchResults: undefined | null | boolean;
  }) => css`
    margin-bottom: ${isPos
      ? theme.spacings.giga
      : headerMargin({ theme, isSearch, searchResults })};
    ${theme.mq.mega} {
      margin-top: calc(2 * ${theme.spacings.tera});
    }
    position: relative;
  `,
);

export const PosGreetingCard = styled(Card)(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--cui-bg-subtle);
    border: none;
    ${theme.mq.untilMega} {
      flex-direction: column-reverse;
    }
  `,
);

export const PosGreetingsContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    ${theme.mq.untilMega} {
      margin-top: ${theme.spacings.kilo};
      text-align: center;
    }
    ${theme.mq.mega} {
      max-width: 50%;
    }
  `,
);

export const NavHeader = styled('header')(
  ({
    theme,
    isNavSearchVisible,
    isSearch,
    isPos,
  }: StyledComponentsParams & {
    isNavSearchVisible: boolean;
    isSearch: boolean;
    isPos: boolean;
  }) => css`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${theme.zIndex.navigation};
    background-color: var(--cui-bg-normal);
    border-bottom: 1px solid var(--cui-bg-subtle-pressed);
    border-bottom: ${!isPos && isNavSearchVisible
      ? '1px solid var(--cui-bg-subtle-pressed)'
      : 'var(--cui-bg-normal)'};
    margin-bottom: ${isSearch ? theme.spacings.tera : 0};
    transition: all 0.3s ease-in-out;
    ${isPos ? `padding: ${theme.spacings.byte} 0;` : ''}

    ${theme.mq.untilMega} {
      margin-bottom: ${theme.spacings.giga};
      ${isPos ? `padding: ${theme.spacings.kilo} 0;` : ''}
    }
  `,
);

export const HeaderLink = styled(Anchor)(
  () => css`
    display: flex;
    color: var(--cui-bg-strong);
    &:visited,
    &:visited:active,
    &:visited:hover,
    &:hover,
    &:active,
    &:focus {
      color: var(--cui-bg-strong);
    }
    display: flex;
    align-items: center;
    text-decoration: none;
  `,
);

export const HeaderLogo = styled(icons.SumUpLogo)(
  ({ theme }: StyledComponentsParams) => css`
    position: relative;
    margin-top: ${theme.spacings.mega};
    margin-bottom: ${theme.spacings.mega};
    margin-right: ${theme.spacings.kilo};
    height: calc(${theme.spacings.tera} + ${theme.spacings.bit});
    ${theme.mq.untilMega} {
      height: ${theme.spacings.tera};
    }
  `,
);

export const POSHeaderLogo = styled(SumupLogo)(
  ({ theme }: StyledComponentsParams) => css`
    position: relative;
    margin-top: ${theme.spacings.mega};
    margin-bottom: ${theme.spacings.mega};
    margin-right: ${theme.spacings.kilo};
    height: calc(${theme.spacings.tera} + ${theme.spacings.bit});
    ${theme.mq.untilMega} {
      height: ${theme.spacings.tera};
    }
  `,
);

export const MenuButton = styled(Button)(
  ({ theme }: StyledComponentsParams) => css`
    text-decoration: none;
    text-align: left;
    justify-content: flex-start;
    padding: ${theme.spacings.byte};
    border: none;
    width: 100%;
    border-radius: ${theme.borderRadius.mega};
    color: var(--cui-bg-strong);
    &:visited,
    &:visited:active,
    &:active,
    &:focus {
      color: var(--cui-bg-strong);
    }

    &:visited:hover,
    &:hover {
      color: var(--cui-bg-accent-strong);

      path {
        fill: var(--cui-bg-accent-strong);
      }
    }
  `,
);

export const LinkToWebsite = styled(Anchor)(
  () => css`
    text-decoration: none;
    color: var(--cui-bg-strong);
    &:visited,
    &:visited:active,
    &:visited:hover,
    &:hover,
    &:active,
    &:focus {
      color: var(--cui-bg-strong);
    }
  `,
);

export const MenuItem = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    border-radius: ${theme.borderRadius.mega};

    svg {
      margin-right: ${theme.spacings.mega};
      width: ${theme.iconSizes.mega};
      height: ${theme.iconSizes.mega};
    }

    ${theme.mq.untilMega} {
      width: 100%;
      display: flex;
      justify-content: center;
      height: ${theme.spacings.exa};
    }
    ${theme.mq.megaToGiga} {
      width: 100%;
      display: flex;
      justify-content: center;
      height: ${theme.spacings.exa};
    }
  `,
);

export const MobileHamburger = styled(Hamburger)(
  ({ theme }: StyledComponentsParams) => css`
    z-index: ${theme.zIndex.popover};
    margin-right: -${theme.spacings.byte};
  `,
);

export const MobileMenu = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--cui-bg-normal);
    z-index: ${theme.zIndex.default};
    padding-top: calc(${theme.spacings.exa} * 3);
    div:last-child {
      border-bottom: ${theme.borderWidth.kilo} solid
        var(--cui-bg-normal-pressed);
    }
  `,
);

export const Navigation = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: ${theme.zIndex.tooltip};
    background-color: var(--cui-bg-normal);
  `,
);

export const NavigationContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;
    position: relative;
    z-index: ${theme.zIndex.absolute};
  `,
);

export const SearchContainer = styled('div')(
  ({ theme, centered }: StyledComponentsParams & { centered: boolean }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: ${centered ? 'center' : 'flex-start'};
    position: relative;
    z-index: ${theme.zIndex.popover};
  `,
);

export const TopNavSearchContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;

    label {
      width: 100%;
      svg {
        height: ${theme.spacings.peta};
      }
    }
  `,
);

export const SearchSuggestionsContainer = styled('div')(
  ({
    theme,
    topNav,
    fullWidth = false,
  }: StyledComponentsParams & { fullWidth?: boolean; topNav?: boolean }) => css`
    width: 100%;
    max-width: ${MAX_SEARCH_WIDTH_PX}px;
    position: absolute;
    z-index: ${theme.zIndex.popover};
    top: ${topNav ? '67px' : '54px'};
    height: 0;

    ${theme.mq.untilMega} {
      width: 100%;
    }

    ${theme.mq.giga} {
      width: ${fullWidth ? '100%' : '50%'};
    }
  `,
);

export const SearchWrapper = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    max-width: ${MAX_SEARCH_WIDTH_PX}px;
    ${theme.mq.giga} {
      width: 50%;
    }

    & > div {
      margin-bottom: ${theme.spacings.kilo};
    }
  `,
);

const NAV_SEARCH_CONTAINER_STYLES = {
  isNavSearchVisible: ({
    theme,
    isNavSearchVisible,
  }: StyledComponentsParams & {
    isNavSearchVisible: boolean;
  }) =>
    isNavSearchVisible
      ? css`
          margin-top: 0;
          ${theme.mq.giga} {
            margin-top: 0;
          }
        `
      : css`
          margin-top: calc(-${theme.spacings.exa} * 2);
          ${theme.mq.giga} {
            margin-top: calc(-${theme.spacings.exa} * 2);
          }
        `,
  isNavSearchExpanded: ({
    theme,
    isNavSearchExpanded,
  }: StyledComponentsParams & {
    isNavSearchExpanded: boolean;
  }) =>
    isNavSearchExpanded
      ? css`
          z-index: ${theme.zIndex.header};
          width: 90%;
          position: absolute;
          left: 50%;
          ${theme.mq.giga} {
            width: 50%;
          }
          ${theme.mq.untilGiga} {
            top: ${theme.spacings.byte};
          }
        `
      : css`
          z-index: ${theme.zIndex.absolute};
          width: calc(${theme.spacings.tera} + ${theme.spacings.kilo});
          position: relative;
          left: calc(${theme.spacings.giga} - ${theme.spacings.bit});
          ${theme.mq.giga} {
            width: calc(${theme.spacings.peta} * 3);
          }
        `,
};

export const NavSearchContainer = styled('div')(
  ({
    theme,
    isNavSearchVisible,
    isNavSearchExpanded,
  }: StyledComponentsParams & {
    isNavSearchVisible: boolean;
    isNavSearchExpanded: boolean;
  }) => css`
    max-width: ${MAX_SEARCH_WIDTH_PX}px;
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translate(-50%, 0);
    transition: all 0.3s ease-in-out;
    transition-property: margin-top, width;

    ${NAV_SEARCH_CONTAINER_STYLES.isNavSearchExpanded({
      theme,
      isNavSearchExpanded,
    })};

    ${NAV_SEARCH_CONTAINER_STYLES.isNavSearchVisible({
      theme,
      isNavSearchVisible,
    })};

    ${theme.mq.giga} {
      position: absolute;
      top: 10px;
      left: 50%;
      transition: all 0.3s ease-in-out;
    }

    & > div:first-child,
    & > div:first-child > div {
      width: 100%;
    }
  `,
);

export const NavSearchBar = styled(SearchInput)(
  ({
    theme,
    isNavSearchExpanded,
  }: StyledComponentsParams & { isNavSearchExpanded: boolean }) => css`
    & input {
      box-shadow: ${isNavSearchExpanded
        ? '0 0 0 1px var(--cui-bg-highlight-pressed)'
        : 'none'};
      text-overflow: ellipsis;
      overflow: hidden;
      padding-right: ${theme.spacings.mega};
    }
  `,
);

export const NavigationWrapper = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    ${theme.mq.untilMega} {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    ${theme.mq.megaToGiga} {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  `,
);

export const NavSearchOverlay = styled('div')(
  ({
    theme,
    isNavSearchExpanded,
  }: StyledComponentsParams & { isNavSearchExpanded: boolean }) => css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: ${theme.spacings.zetta};
    z-index: ${isNavSearchExpanded
      ? `${theme.zIndex.tooltip}`
      : `${theme.zIndex.default}`};
    background-color: ${isNavSearchExpanded
      ? 'var(--cui-bg-normal)'
      : 'transparent'};
    transition: all 0.3s ease-in-out;
  `,
);

// Fix for passing custom props to the HTML Element and getting errors, such as
// React does not recognize the prop {customProp} on a DOM element.
export const NavBarClear = styled(({ isNavSearchExpanded, ...props }) => (
  <Close {...props} />
))(
  ({
    theme,
    isNavSearchExpanded,
  }: StyledComponentsParams & { isNavSearchExpanded: boolean }) => css`
    cursor: pointer;
    border-radius: 50%;
    width: ${theme.iconSizes.giga};
    height: ${theme.iconSizes.giga};
    margin-left: ${theme.spacings.byte};
    display: ${isNavSearchExpanded ? 'block' : 'none'};
    padding: ${theme.spacings.bit};
    &:hover {
      background-color: var(--cui-bg-subtle);
    }
  `,
);

export const AvatarContainer = styled(Button)(
  ({
    theme,
    isMenuOpened,
  }: StyledComponentsParams & { isMenuOpened: boolean }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    width: ${theme.spacings.exa};
    height: ${theme.spacings.exa};
    background-color: ${isMenuOpened
      ? 'var(--cui-bg-accent-disabled)'
      : 'transparent'};
    border-radius: ${theme.borderRadius.mega};
    padding: ${theme.spacings.byte};
    &:hover {
      background-color: var(--cui-bg-subtle);
    }
    img {
      width: ${theme.spacings.tera};
      height: ${theme.spacings.tera};
    }
  `,
);

export const LoggedInMenu = styled(Card)(
  ({
    theme,
    isMenuOpened,
  }: StyledComponentsParams & { isMenuOpened: boolean }) => css`
    position: absolute;
    right: 0;
    top: ${theme.spacings.zetta};
    padding: 0;
    border: none;
    height: ${isMenuOpened ? 'auto' : '0'};
    padding: ${isMenuOpened ? theme.spacings.kilo : '0'};
    box-shadow: ${isMenuOpened ? '0px 3px 8px rgba(0, 0, 0, 0.2)' : 'none'};
    transition: all 0.2s ease-in-out;
  `,
);

export const HeaderCountryPickerContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    position: absolute;
    top: calc(${theme.spacings.zetta} + ${theme.spacings.mega});
    left: 0;
    width: ${COUNTRY_PICKER_WIDTH}px;
    height: ${COUNTRY_PICKER_HEIGHT}px;
    overflow-y: scroll;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
    background-color: var(--cui-bg-normal);
    border-radius: ${theme.borderRadius.byte};

    .selected {
      color: var(--cui-bg-accent-strong);
      background-color: var(--cui-bg-subtle);
    }
  `,
);

export const HeaderCountryPickerContainerMobile = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: 0;
`;

export const HeaderCountryPickerOption = styled(Anchor)(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    text-decoration: none;
    border-radius: ${theme.borderRadius.bit};
    margin: ${theme.spacings.bit} ${theme.spacings.mega};
    &:visited,
    &:visited:active,
    &:active,
    &:focus {
      color: var(--cui-bg-strong);
    }
    &:hover,
    &:visited:hover {
      color: var(--cui-bg-accent-strong);
      background-color: var(--cui-bg-subtle);
    }

    svg {
      width: ${theme.spacings.tera};
      height: ${theme.spacings.giga};
      margin-right: ${theme.spacings.kilo};
    }

    ${theme.mq.giga} {
      width: ${COUNTRY_PICKER_BUTTON_WIDTH}%;
      margin: ${theme.spacings.bit} ${theme.spacings.mega};
    }
  `,
);

export const HeaderCountryPickerOptionContent = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    display: flex;
    align-items: center;
    border-radius: ${theme.borderRadius.bit};
    padding: ${theme.spacings.kilo};
    color: var(--cui-bg-strong);
    &:hover,
    &:visited:hover {
      color: var(--cui-bg-accent-strong);
    }
  `,
);

export const CloseCountryPickerButtonContainer = styled('div')(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    height: calc(${theme.spacings.tera} + ${theme.spacings.exa});
    top: 0;
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: ${theme.spacings.kilo};
    border-top-left-radius: calc(${theme.borderRadius.mega} * 4);
    border-top-right-radius: calc(${theme.borderRadius.mega} * 4);
    z-index: ${theme.zIndex.popover};
    background-color: var(--cui-bg-normal);
  `,
);

export const CloseCountryPickerButton = styled(Button)(
  ({ theme }: StyledComponentsParams) => css`
    position: absolute;
    top: ${theme.spacings.mega};
    right: ${theme.spacings.mega};
    border: none;
    background-color: transparent;
    padding: 0;
    width: ${theme.spacings.mega};
    height: ${theme.spacings.mega};
  `,
);

export const CloseIcon = styled(Close)(
  () => css`
    color: var(--cui-bg-accent-strong);
  `,
);

export const CountryPickerTabs = styled(TabList)(
  ({ theme }: StyledComponentsParams) => css`
    box-shadow: none;
    position: sticky;
    margin-top: ${theme.spacings.exa};
    top: 0;
    width: 100%;
    height: calc(${theme.spacings.mega} + ${theme.spacings.exa});
    border-top-left-radius: ${theme.borderRadius.byte};
    border-top-right-radius: ${theme.borderRadius.byte};
    z-index: ${theme.zIndex.default};
    padding-top: ${theme.spacings.giga};
    ${theme.mq.giga} {
      height: ${theme.spacings.exa};
      position: fixed;
      width: ${COUNTRY_PICKER_WIDTH}px;
      top: calc(${theme.spacings.zetta} + ${theme.spacings.mega});
      padding-top: 0;
    }
  `,
) as React.ComponentType<Record<string, unknown>>;

// Need to redefine type for styled TabList as Circuit causes error ts(2322)
export const TabButton = styled(Tab)(
  ({
    theme,
    selected,
  }: StyledComponentsParams & {
    selected: boolean;
    children?: Children;
  }) => css`
    margin: 0;
    border-bottom-color: var(--cui-bg-normal-pressed);
    border-bottom-style: solid;
    border-bottom-width: ${theme.borderWidth.kilo};
    z-index: ${selected ? theme.zIndex.input : theme.zIndex.absolute};
    &:active {
      z-index: ${theme.zIndex.input};
    }
    &:hover {
      ${theme.mq.untilMega} {
        background-color: var(--cui-bg-normal);
      }
    }
    &::after {
      height: ${TAB_BUTTON_HIGHLIGHT_HEIGHT}px;
      bottom: ${TAB_BUTTON_HIGHLIGHT_OVERLAP}px;
    }
  `,
) as React.ComponentType<{
  selected: boolean;
  onClick: () => void;
  children?: Children;
}>;

export const TabsContainer = styled.div(
  ({ theme }: StyledComponentsParams) => css`
    position: relative;
    width: 100%;
    top: -${theme.spacings.exa};
    ${theme.mq.giga} {
      top: 0;
    }
  `,
);

export const TabContent = styled(TabPanel)(
  ({
    theme,
  }: StyledComponentsParams & {
    children?: Children;
  }) => css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    background-color: var(--cui-bg-normal);
    margin-bottom: ${theme.spacings.mega};
    margin-top: ${theme.spacings.mega};
    ${theme.mq.giga} {
      margin-top: calc(${theme.spacings.exa} + ${theme.spacings.mega});
    }
  `,
);

export const TabListBorder = styled.div(
  ({ theme }: StyledComponentsParams) => css`
    width: 100%;
    height: 1px;
    background-color: var(--cui-bg-normal-pressed);
    position: absolute;
    top: calc(${theme.spacings.exa} + ${theme.spacings.mega} - 1px);
    ${theme.mq.giga} {
      top: calc(${theme.spacings.exa} - 1px);
  `,
);
