const env: (variable: string) => string = (variable: string) => {
  const envMap: {
    [key: string]: string;
  } = {
    ENV: process.env.NEXT_PUBLIC_ENV,
    NEXT_PUBLIC_SEARCH_SERVICE_ENDPOINT:
      process.env.NEXT_PUBLIC_SEARCH_SERVICE_ENDPOINT,
    CONTENTFUL_SPACE_ID: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
    CONTENTFUL_ENV: process.env.NEXT_PUBLIC_CONTENTFUL_ENV,
    CONTENTFUL_TOKEN_DELIVERY:
      process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN_DELIVERY,
    CONTENTFUL_TOKEN_PREVIEW: process.env.NEXT_PUBLIC_CONTENTFUL_TOKEN_PREVIEW,
    CONTENTFUL_PREVIEW_HOST: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_HOST,
    OIDC_ISSUER_URL: process.env.NEXT_PUBLIC_OIDC_ISSUER_URL,
    NEXT_PUBLIC_OIDC_ISSUER_URL: process.env.NEXT_PUBLIC_OIDC_ISSUER_URL,
    OIDC_CLIENT_ID: process.env.NEXT_PUBLIC_OIDC_CLIENT_ID,
    NEXT_PUBLIC_OIDC_CLIENT_ID: process.env.NEXT_PUBLIC_OIDC_CLIENT_ID,
    OIDC_CLIENT_REDIRECT_URI: process.env.NEXT_PUBLIC_OIDC_CLIENT_REDIRECT_URI,
    NEXT_PUBLIC_OIDC_CLIENT_REDIRECT_URI:
      process.env.NEXT_PUBLIC_OIDC_CLIENT_REDIRECT_URI,
    OIDC_CLIENT_SCOPES: process.env.NEXT_PUBLIC_OIDC_CLIENT_SCOPES,
    NEXT_PUBLIC_OIDC_CLIENT_SCOPES: process.env.NEXT_PUBLIC_OIDC_CLIENT_SCOPES,
    API_GATEWAY_HOST: process.env.NEXT_PUBLIC_API_GATEWAY_HOST,
    API_GATEWAY_PROTO: process.env.NEXT_PUBLIC_API_GATEWAY_PROTO,
  };

  return envMap[variable];
};

export default env;
